.wrapper-content-header {
    padding: 96px 16px 40px;
    background: #0d1219;
    text-align: center;
    color: #fff;
    font-size: 34px;
    font-family: $font-opensans-light;

    h1 {
        margin: 0 0 0 0;
        text-transform: uppercase;
        font-size: 34px;
    }

    &.advert {
        background: url(../../../public/assets/img/products/bandeau-pub.jpg) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    &.selling {
        background: url(../../../public/assets/img/products/bandeau-vente.jpg) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    &.services {
        background: url(../../../public/assets/img/products/bandeau-services.jpg) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}

.sections-container {
    &.products {
        .wrapper-content-body {
            padding-top: 60px;
        }
    }
}

.wrapper-content-body {
    background: #fff;
    color: #223240;
    font-size: 1.25rem;

    h1 {
        font-size: 2.5rem;
        color: #223240;
        text-transform: uppercase;
        max-width: 900px;
        margin: 0px auto;
        padding: 32px 16px 0;
    }

    p {
        max-width: 900px;
        margin: 0 auto;
        padding: 16px;

        a {
            color: #223240;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .wrap-intro {
        display: grid;
        grid-template-columns: 52px auto;
        align-items: center;
        max-width: 900px;
        margin: 0px auto;

        p {
            font-size: 1.125rem;
            margin: 0;
        }
    }

    .wrap-outro {
        display: grid;
        grid-template-columns: 32px auto;
        align-items: center;
        max-width: 900px;
        margin: 24px auto;

        a {
            color: #223240;
        }
    }

    ul {
        padding-left: 34px;
        padding-right: 16px;
        max-width: 900px;
        text-align: left;
        display: block;
        margin: 0 auto;

        a {
            color: #223240;

            &:hover {
                text-decoration: none;
            }
        }

        li {
            line-height: 2rem;
            font-size: 1.25rem;
            list-style: disc;
            color: #223240;
        }
    }

    ul.three-columns {
        display: grid;
        grid-template-columns: auto auto auto;
    }

    ul.left-list {
        padding-left: 10%;
    }

    ul.list-tick {
        max-width: 864px;
        position: relative;
        list-style: none;

        li {
            list-style: none;

            &::before {
                content: "";
                display: block;
                background-image: url(../../assets/img/check_icon.png);
                background-size: 30px;
                width: 30px;
                height: 30px;
                position: absolute;
                left: 0;
            }
        }
    }

    .wp-block-image {
        background: #f4f4f4;
        margin: 0;
        padding: 32px 16px;
        margin: 16px 0;

        figcaption {
            display: block;
            margin: 0 auto;
            text-align: center;
            margin-top: 16px;
            margin-bottom: 30px;
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 690px;
            height: auto;
            width: 100%;
        }

        &.icon-margin-top {
            margin-top: 20px;
        }
    }

    .wp-block-image.no-background {
        background: unset;
        padding: 0 16px;
    }

    .column-icon-title {
        display: grid;
        grid-template-columns: 30px auto;
        justify-content: start;
        gap: 8px;
        align-items: center;

        .wp-block-image {
            padding: 0;
            background: unset;
        }
    }

    .column-icon-title.top {
        align-items: start;
    }

    .column-icon-title.small {
        grid-template-columns: 30px auto;

        img {
            width: 20px !important;
        }
    }
}

.wrapper-content-footer {
    padding: 40px 16px;
    background: #fff;

    .title {
        text-transform: uppercase;
        font-size: 2rem;
        margin-bottom: 24px;
        color: #313131;
    }

    ul {
        grid-gap: 16px;
        display: flex;
        flex-wrap: wrap;

        li {
            background: #f4f4f4;
            display: flex;
            align-items: center;

            &:hover {
                background: #eeeeee;
            }

            a {
                display: flex;
                width: 140px;
                height: 70px;
                font-size: 16px;
                align-items: center;
                padding: 16px;
                text-transform: uppercase;
                font-family: $font-opensans-bold;
                color: #313131;
                text-decoration: none;
                text-align: center;
                justify-content: space-around;
            }
        }
    }
}


.size-medium.small img {
    max-width: 200px !important;
}

.size-medium img {
    max-width: 550px !important;
}

.wp-block-columns.full-page {
    max-width: 100%;
    justify-content: start;
    display: block;

    .wp-block-column {
        max-width: 900px;
        margin: 0 auto;
    }
}


.wp-block-buttons {
    text-align: center;
    margin: 24px 0;

    a {
        display: grid;
        background: #4e4e4e;
        border: 1px solid #4e4e4e;
        color: #fff;
        border-radius: 20px;
        width: 170px;
        text-align: center;
        padding: 6px 6px 8px;
        font-family: $font-opensans-bold;
        text-decoration: none;
        margin: 0 auto;
    }
}

.block-margin {
    margin: 40px 0;

    .wp-block-group__inner-container {
        max-width: 900px;
        margin: 0 auto;
    }

    .margin-top-20 {
        margin-top: 20px;
    }
}

#block-margin .margin-top-20 {
    margin-top: 20px;
}

p.no-padding-side {
    padding-left: 0;
    padding-right: 0;
}

@media screen and (max-width: 1100px) {
    .wrapper-content-footer ul {
        justify-content: center;
    }
}


@media screen and (max-width: 900px) {

    .wrapper-content-body ul.list-tick {
        padding-left: 50px;

        li::before {
            left: 16px;
        }
    }

}

@media screen and (max-width: 800px) {
    .wrapper-content-body {
        ul.three-columns {
            grid-template-columns: auto auto;
        }
    }
}

@media screen and (max-width: 710px) {
    .wrapper-content-body ul.left-list {
        padding-left: 86px;
    }


}

@media screen and (max-width: 610px) {
    .wrapper-content-body {
        ul.three-columns {
            grid-template-columns: auto;
        }
    }
}