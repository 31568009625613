.navbar {
    z-index: 999;
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    background: linear-gradient(to bottom right, #15161a, #212528);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 1px 3px 0 rgb(0 0 0 / 12%);

    button {
        display: none;
    }
}

.navbar-logo {
    img {
        width: 200px;
        display: block;
    }
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    animation: fadeIn 0.5s;
    -webkit-animation: fadeIn 0.5s;
    -moz-animation: fadeIn 0.5s;
    -o-animation: fadeIn 0.5s;
    -ms-animation: fadeIn 0.5s;
}

.navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-expand-md {
    .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;

        .nav-link {
            color: #fff;
            font-size: 1.125rem;
            padding: 5px 0px;
            margin-left: 30px;
            position: relative;
            text-decoration: none;
            letter-spacing: 2px;
        }
    }

    .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.nav-item {
    text-transform: uppercase;
}


.navbar>.container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.container {
    width: 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;

    .custom-language-switcher {
        position: relative;
        margin-left: 26px;

        .language-switcher {
            background-color: transparent;
            background-image: url(../../assets/img/arrow_down_icon.png);
            background-size: 24px;
            background-position: 48px;
            background-repeat: no-repeat;
            color: #fff;
            outline: none;
            border: none;
            font-family: "font-opensans-light", sans-serif;
            font-size: 1rem;
            -webkit-appearance: none;
            padding-right: 24px;
        }
    }
}

@media (max-width: 1100px) {

    .navbar {
        background: linear-gradient(to bottom right, #15161a, #212528);
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 16%), 0 1px 3px 0 rgb(0 0 0 / 12%);
        position: fixed;
        width: 100%;
        box-sizing: border-box;

        button {
            display: block;
            border: none;
            outline: none;
            width: 36px;
            height: 36px;
            background: transparent;
            cursor: pointer;

            .hambgr {
                hr {
                    height: 2px;
                    outline: none;
                    border: none;
                    background: #fff;
                }
            }

            .nav-item {
                line-height: 40px;
            }
        }
    }

    .navbar-expand-md {
        .navbar-collapse {
            display: none !important;
            align-items: inherit;
            text-align: right;
            flex-grow: unset;

            .language-switcher {
                margin-left: 8px;
                margin-top: 14px;
                margin-bottom: 8px;
            }
        }

        .navbar-collapse.show {
            display: block !important;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
            -ms-flex-positive: 1;
            flex-grow: 1;
            -ms-flex-align: center;
            align-items: center;
            text-align: center;
            transition: 0.6s;

            .navbar-nav {
                -ms-flex-direction: row;
                flex-direction: row;
                flex-direction: column;
                width: 100% !important;
                margin-right: auto !important;
                margin-top: 12px;
                gap: 16px;
            }
        }
    }

}