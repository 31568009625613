.sections-container {
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    scroll-padding: 56px;
    scroll-behavior: smooth;
}

.section {
    height: auto;
    scroll-snap-align: start;
    overflow: hidden;
    display: grid;
    align-items: center;

    /*border: 1px solid red;*/
}

section#home img {
    width: 100%;
    height: auto;
}

section#home {
    //height: 100vh;

    .wrapper-block {
        position: relative;

        .title {
            color: #fff;
            font-size: 50px;
            padding: 0 60px;
            text-align: center;
            margin: auto;
            line-height: 80px;

            h1 {
                text-shadow: 3px 6px 4px black, 0 0 1px black, 0 0 4px black;
                font-family: $font-opensans-bold;
            }
        }
    }

    .image {
        background: url(../../../public/assets/img/home.jpg) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 100vh;
        display: flex;
        align-items: center;
    }
}



.section .two-columns {
    display: grid;
    grid-template-columns: auto 300px;

    h1 {
        font-size: 60px;
        font-family: $font-opensans-bold;
        color: #313131;
        margin: 0;
        line-height: 64px;
    }

    p {
        font-size: 24px;
        color: #313131;

        a {
            color: #313131;

            &:hover {
                text-decoration: none;
            }
        }


    }
}




.section .purple {
    background: #3A0B61;
    color: #fff;
    height: 100vh;
}

section .section-body {
    max-width: 1100px;
    display: grid;
    align-items: center;
    margin: 0 auto;
    padding: 40px 24px;
}

section#presentation .section-body {
    height: 100%;
    padding-top: 0;
}

section#sites .section-body {
    padding-top: 0;
}

section#presentation {
    align-items: start;
    height: 94vh;
}

.section#presentation .two-columns {
    grid-gap: 40px;
    display: grid;
    grid-template-columns: auto 50vh;
}

.section#presentation .two-columns img {
    width: 100%;
    height: auto;
}

.section#presentation .two-columns .column {
    padding: 0;
    display: flex;
    align-items: center;
}

.section#presentation .two-columns .column.second {
    padding: 40px 40px 40px 0;
    display: grid;
    align-self: center;
}

section#sites h1,
section#contact h1,
section#products h1,
section#team h1,
section#references h1 {
    font-weight: 300;
    font-size: 48px;
    text-align: center;
    display: block;
    width: 100%;
    font-family: $font-opensans-bold;
    margin-top: 0px;
    color: #313131;
    margin-bottom: 40px;
}

section#products {
    background: #fff;
    height: 94vh;
}

section#references {
    height: 94vh;
}

section#sites,
section#contact {
    background: #f6f6f6;
    height: 94vh;
}

section#sites {

    .description {
        font-size: 24px;
        color: #313131;
    }

    ul {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 40px;
        margin: 0 auto;

        li {
            background-color: #fff;
            height: 150px;
            display: grid;
            align-items: center;
            padding: 24px;
            border-radius: 4px;
            box-shadow: 3px 3px 15px rgb(0 0 0 / 8%);
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
            overflow: hidden;
            position: relative;

            a {
                display: grid;
                width: 100%;
                height: 100%;
                align-items: center;
                box-sizing: border-box;
                text-decoration: none;
            }

            img {
                max-width: 100%;
                width: 225px;
                margin: 0 auto;
                height: auto;
                -webkit-transition-duration: 4000ms;
                -o-transition-duration: 4000ms;
                transition-duration: 4000ms;

                &.smaller {
                    width: 150px;
                }
            }

            .single-content {
                background-color: rgb(0 0 0);
                opacity: 0.90;
                height: 100%;
                left: 0;
                opacity: 0;
                position: absolute;
                top: 0;
                -webkit-transition-duration: 500ms;
                -o-transition-duration: 500ms;
                transition-duration: 500ms;
                width: 100%;

                .linkto {
                    display: grid;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    font-family: $font-opensans-bold;
                    color: #fff;
                    font-size: 30px;
                }
            }

            &:hover img {
                -webkit-transform: scale(1.1);
                -ms-transform: scale(1.1);
                transform: scale(1.1);
            }
        }



        li:hover:hover .single-content {
            opacity: 0.8;
        }

        li:hover:hover img {
            opacity: 0.4;
        }


    }
}


section#team {

    background: #f6f6f6;

    ul {
        max-width: 1100px;
        display: grid;
        grid-template-columns: 230px 230px 230px 230px;
        margin: 0 auto;
        text-align: center;
        grid-gap: 24px;

        li {
            img {
                width: 13vh;
                height: 13vh;
                border-radius: 13vh;
            }

            .title {
                display: block;
                color: #313131;
                font-size: 22px;
                font-family: $font-opensans-bold;
            }

            .subtitle {
                display: block;
                color: #313131;
                font-size: 22px;
                font-weight: 300;
            }
        }
    }
}



section#team ul li section#products .round-image {
    width: 60px;
    height: 60px;
    background: #f6f6f6;
    display: grid;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 60px;
    margin: 0 auto;
}

section#products {
    .wrap-header {
        display: grid;
        grid-template-columns: auto;
        align-items: center;
        text-align: center;
        justify-content: center;
        grid-gap: 16px;

        h2 {
            text-align: left;
            margin-top: 0;
        }
    }

    img {
        display: block;
        margin: 0 auto;
        width: 30px;
    }

    h2 {
        font-size: 22px;
        color: #313131;
        text-align: center;
        font-weight: 300;
        margin-top: 12px;
        font-family: $font-opensans-bold;
    }

    p {
        font-size: 22px;
        color: #313131;
        margin-top: 0;

        a {
            color: #313131;

            &:hover {
                text-decoration: none;
            }
        }
    }

    ul {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 40px;

        a {
            text-decoration: none;
        }

        li {
            background: #f6f6f6;
            border: 1 px solid #efefef;
            border-radius: 4px;
            padding: 24px 40px;
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
            text-align: center;

            .button {
                text-decoration: underline;
                color: #313131;
                font-size: 20px;
                cursor: pointer;
            }

            &:hover {
                -webkit-transform: scale(1.1);
                -ms-transform: scale(1.1);
                transform: scale(1.1);

                .button {
                    text-decoration: none;
                }
            }
        }
    }

    .wrap-infos {
        text-align: center;
        margin-top: 16px;

        h3 {
            font-size: 30px;
            color: #313131;
        }

        p {
            font-size: 24px;
        }

    }
}

section#references {
    ul {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        grid-gap: 24px;

        li {
            text-align: center;

            img {
                width: 170px;
                max-width: 100%;
                height: auto;
                filter: saturate(0);
            }

            &:hover img {
                filter: saturate(1);
            }
        }
    }
}


section#contact {

    .section-body {
        max-width: 100%;
        width: 1000px;
        box-sizing: border-box;
    }

    .wrapper-contact {
        display: grid;
        grid-template-columns: auto 400px;
        grid-gap: 40px;

        p {
            font-size: 22px;
            color: #313131;
            margin-top: 8px;
            margin-bottom: 8px;
        }

        img {
            width: 30px;
        }

        .left {
            img {
                margin-bottom: 8px
            }
        }

        form {
            display: grid;
            grid-gap: 8px;

            .form-item {
                display: grid;
                grid-gap: 8px;
            }

            label {
                font-size: 22px;
                color: #313131;
            }

            input[type="text"],
            input[type="email"],
            textarea {
                border: 1px solid #bbbbbb;
                border-radius: 4px;
                padding: 8px;
                font-size: 1.1rem;
                padding: 16px 30px;
                border-radius: 30px;
                border: 1px solid transparent;
                background: #ffffff;
                font-family: $font-opensans-light;

                &.has-error {
                    border: 1px solid red;
                }
            }

            p.error {
                color: red;
            }

            textarea {
                height: 160px;
            }

            button {
                background: #4e4e4e;
                color: #fff;
                font-family: $font-opensans-bold;
                font-size: 20px;
                outline: none;
                border: 1px solid #4e4e4e;
                border-radius: 40px;
                padding: 8px;
                margin-top: 8px;

                &:disabled {
                    opacity: 0.5;
                }
            }

            input.error,
            textarea.error {
                border: 1px solid red;
            }
        }

        #errorMessage,
        #resultSender.error {
            color: #721c24;
            background-color: #f8d7da;
            border-color: #f5c6cb;
            padding: 0.75em 1.25em;
            margin-bottom: 1em;
            border: 1px solid transparent;
            border-radius: 0.25em;
            margin-top: 0px;
            font-family: $font-opensans-bold;
        }

        #resultSender.success {
            background-color: #32D190;
            color: #fff;
            padding: 0.75em 1.25em;
            margin-bottom: 0;
            border: 1px solid transparent;
            border-radius: 1.5em;
            margin-top: 0px;
            font-family: $font-opensans-bold;
        }
    }
}

.grecaptcha-badge {
    display: none;
}

body .wrapper-content-body {
    color: #313131;
}

.wp-block-columns {
    display: flex;
    grid-gap: 16px;
    justify-content: center;
    padding: 16px;
    max-width: 900px;
    margin: 0 auto;
}

@media (max-width: 1100px) {

    section#sites h1,
    section#contact h1,
    section#products h1,
    section#team h1 {
        margin-top: 0;
    }

    .sections-container {
        scroll-padding: 68px;
    }

    section#presentation {
        height: auto;
    }

    .sections-container {
        height: auto;
        scroll-snap-type: unset;
        overflow-y: unset;
        scroll-padding: unset;
    }

    .section {
        height: auto;
    }

    section#home {
        height: auto;
    }

    .wrapper-home {
        grid-template-rows: auto;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
    }

    .section#presentation .two-columns {
        grid-gap: 40px;
        grid-template-columns: auto;

        h1 {
            text-align: center;
        }
    }

    section#sites .section-body,
    section#contact .section-body,
    section#products .section-body {
        height: auto;
        padding: 40px 24px;
    }

    section#presentation .section-body {
        height: auto;
        padding: 40px 24px 24px;
    }

    .section#presentation .two-columns .column.second {
        padding: 0;
    }

    .section#presentation .two-columns .column {
        display: none;
    }

    section#references,
    section#sites,
    section#contact,
    section#products,
    section#presentation {
        height: auto;
    }

    section#products .wrap-infos p {
        margin-bottom: 0;
    }

    .section .purple {
        height: auto;
        padding: 70px 30px 0px;
    }

    section#home .image {
        height: 64vh;
        display: flex;
        padding-top: 68px;
    }

    section#home .wrapper-block .title h1 br {
        display: none;
    }

    section#home .wrapper-block .title {
        color: #fff;
        font-size: 34px;
        position: initial;
        margin: auto;
        height: auto;
        line-height: 70px;

        h1 {
            margin: 0 30px;
            text-align: center;
        }
    }

    .section .two-columns h1,
    section#sites h1,
    section#products h1 {
        font-size: 40px;
        line-height: 44px;
    }

    .section .two-columns {
        p {
            font-size: 22px;
        }
    }

    section#team ul {
        grid-template-columns: 230px 230px 230px;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {

    .wrapper-home {
        grid-template-columns: auto;
        grid-template-rows: 18vh auto;
    }

    section#home .image {
        height: 82vh;
    }

    section#presentation {
        align-items: start;
        height: 50vh;
    }

}

@media screen and (max-width: 960px) {
    section#contact .section-body {
        width: 890px;
    }

}

@media screen and (max-width: 850px) {


    .section#presentation .two-columns img {
        width: 100%;
        margin: 0 auto;
        display: block;
    }

    section#contact .wrapper-contact {
        grid-template-columns: auto;
    }

    section#contact .wrapper-contact p,
    section#contact .wrapper-contact form label,
    section#contact .wrapper-contact form input[type="text"],
    section#contact .wrapper-contact form textarea {
        font-size: 22px;
    }

    section#contact .wrapper-contact .right {
        display: grid;
        grid-template-columns: auto;
        grid-gap: 16px;
    }

    section#contact .wrapper-contact .right div {
        text-align: center;
    }

    section#contact .wrapper-contact img {
        width: 24px;
    }

    section#contact .wrapper-contact form textarea {
        height: 100px;
    }

    section#contact .wrapper-contact form button {
        font-size: 16px;
    }

    section#team ul {
        grid-template-columns: auto auto auto;
    }

    section#sites li {
        height: 100px;
    }

    section#contact .section-body {
        width: 100%;
    }
}

@media (max-width: 768px) {

    section#home .image {
        height: 70vh;
    }

    section#references ul {
        grid-template-columns: auto auto auto auto;
    }

    section#team ul {
        grid-template-columns: 230px 230px;
    }
}

@media screen and (max-width: 695px) {
    .section .two-columns {
        grid-template-columns: auto;
    }
}


@media screen and (max-width: 680px) {
    .wp-block-columns {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 650px) {

    .wrapper-home {
        grid-template-columns: auto;
        grid-template-rows: auto;
    }

    section#home .image {
        height: 40vh;
    }

    section#home .wrapper-block .title {
        color: #fff;
        font-size: 30px;
        position: initial;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: auto;
        line-height: 60px;
    }

    .section#presentation .two-columns {
        display: block;
    }

    .section#presentation .two-columns .column {
        padding: 0;
    }
}

@media screen and (max-width: 580px) {
    section#products ul {
        grid-template-columns: auto;
    }

    section#home .wrapper-block .title {
        font-size: 22px;
        line-height: 42px;
    }


    section#sites ul {
        grid-template-columns: auto auto;
    }

    .section .two-columns h1,
    section#sites h1,
    section#products h1 {
        //margin-top: 30px;
    }

    section#team ul {
        grid-template-columns: auto auto;
    }

    section#references ul {
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 510px) {
    section#products ul {
        grid-template-columns: auto;
    }


}

@media screen and (max-width: 413px) {

    section#sites ul,
    section#team ul {
        grid-template-columns: auto;
    }
}