.sections-container.values {
    background: #fff;
    padding-top: 56px;
}

.wrapper-values {
    text-align: center;
    color: #223240;
    min-height: 500px;
    background: #fff;
    position: relative;
}

.values-body {

    .header {
        min-height: 150px;
        background: url(../../../public/assets/img/products/bandeau-valeurs.jpg) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .body {
        max-width: 900px;
        margin: 0px auto;
        padding: 32px 16px 80px;
        text-align: left;
        font-size: 22px;
    }

    h1 {
        text-transform: uppercase;
        margin: 0;
        color: #fff;
        font-size: 34px;
    }


    .wp-block-columns {

        padding: 0;

        display: grid;
        grid-template-columns: 20px auto;
        justify-content: start;
        gap: 8px;
        align-items: center;
        padding: 0;

        .wp-block-column {
            display: block;

            figure {
                padding: 0;
                margin: 16px 0;
                background: unset;

                img {
                    display: block;
                    margin: 0 auto;
                    max-width: 900px;
                    height: auto;
                    width: 100%;
                }
            }

            &:last-child {
                padding: 16px;

                a {
                    color: #223141;

                    &:hover {
                        text-decoration: none;
                    }
                }

            }
        }
    }
}