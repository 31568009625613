.sections-container.error {
    background: linear-gradient(180deg, rgba(16, 22, 28, 1) 0, rgba(33, 49, 64, 1) 35%);
    padding-top: 56px;
}

.wrapper-error {
    text-align: center;
    color: #223240;
    height: 500px;
    background: #fff;
    position: relative;
}

.error-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.wrapper-error {
    span {
        font-size: 2rem;
    }
}

.wrapper-error {
    a {
        color: #223240;
        font-size: 20px;

        &:hover {
            text-decoration: none;
        }
    }


}