.sections-container.af {
    background: #fff;
    color: #223240;
    font-size: 20px;
}

.logo-af {
    padding: 100px 16px 40px;
    background: linear-gradient(to bottom, #0d1219, #1f2d3c);
    text-align: center;

    img {
        width: 100%;
        height: auto;
        max-width: 441px;
    }
}


.logo-rigtalk {
    padding: 100px 16px 40px;
    background: linear-gradient(to bottom, #000000, #e84e0d);
    text-align: center;

    img {
        width: 100%;
        height: auto;
        max-width: 165px;
    }
}


.logo-futureproducers {
    padding: 100px 16px 40px;
    background: #000;
    text-align: center;

    img {
        width: 100%;
        height: auto;
        max-width: 200px;
    }
}

.logo-homerecording {
    padding: 100px 16px 40px;
    background: linear-gradient(to bottom, #000000, #d9e5f3);
    text-align: center;

    img {
        width: 100%;
        height: auto;
        max-width: 216px;
    }
}

.logo-musicgearreview {
    padding: 100px 16px 40px;
    background: linear-gradient(to bottom, #000000, #71071b);
    text-align: center;

    img {
        width: 100%;
        height: auto;
        max-width: 216px;
    }
}

.wrapper-content-body {
    background: #fff;

    h1.main {
        text-transform: uppercase;
        max-width: 900px;
        margin: 0px auto;
        padding: 32px 16px 0;

        span {
            text-transform: initial;
            font-size: 2rem;
        }

        .flag img {
            width: 30px;
            height: auto;
            margin-left: 8px;
        }
    }

    a.main {
        color: #223240;
        display: block;
        max-width: 900px;
        margin: 0px auto;
        padding: 0 16px 0;

        &:hover {
            text-decoration: none;
        }
    }



    p {
        max-width: 900px;
        margin: 0 auto;
        padding: 32px 16px;
    }

    .wp-block-image {
        background: #f4f4f4;
        margin: 0;
        padding: 32px 16px;

        img {
            display: block;
            margin: 0 auto;
            max-width: 900px;
            height: auto;
            width: 100%;
        }
    }

    .bloc-footer {
        background: #f4f4f4;
        color: #223240;

        .wp-block-group__inner-container {
            max-width: 900px;
            margin: 0 auto;
            padding: 32px 16px;
        }

        .title {
            font-size: 1.5rem;
            padding: 0;
        }

        .bloc-two .title {
            font-size: 1.25rem;
            margin-bottom: 16px;
        }

        .main-description {
            margin: 20px 0;
            padding: 0;
        }

        .bloc-one {
            display: grid;
            width: 100%;
            grid-template-columns: 30px auto;
            max-width: 100%;
            padding: 0;
            justify-content: start;
            margin: 0;
            align-items: center;
            margin-left: 16px;

            p {
                padding: 0;
            }

            span {
                text-decoration: underline;
            }
        }

        .bloc-two {
            display: grid;
            grid-template-columns: 30px auto 30px auto;
            justify-content: start;
            max-width: 100%;

            p {
                padding: 0;
            }

            span {
                text-decoration: underline;
            }
        }

        .contact-button {
            text-align: center;
            margin: 24px 0 0 0;

            a {
                display: grid;
                background: #4e4e4e;
                border: 1px solid #4e4e4e;
                color: #fff;
                border-radius: 20px;
                width: 150px;
                text-align: center;
                padding: 6px 6px 8px;
                font-family: $font-opensans-bold;
                text-decoration: none;
                margin: 0 auto;
            }
        }

        .wp-block-image {
            padding: 0;
            margin: 0;

            img {
                width: 24px;
            }
        }
    }
}



.wrapper-footer {
    background: #f4f4f4;
    color: #223240;

    .title {
        font-size: 1.5rem;
    }

    .footer-content {
        max-width: 900px;
        margin: 0 auto;
        padding: 32px 16px;

        .bloc-two {
            display: grid;
            grid-template-columns: auto auto;
            margin-top: 32px;
            grid-gap: 16px;

            .title {
                font-size: 1.1rem;
                text-transform: uppercase;
                font-family: $font-opensans-bold;
                margin-bottom: 16px;
            }

            ul {
                list-style: none;
                line-height: 28px;
            }

            .options {
                span {
                    text-decoration: underline;
                }
            }


        }

        .bloc-two>div {
            display: grid;
            grid-template-columns: 32px auto;
            grid-gap: 16px;
        }
    }
}


.wrapper-sites {
    margin-top: 24px;

    ul {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        grid-gap: 16px;
    }

    a {
        color: #223240;

        &:hover {
            text-decoration: none;
        }
    }


}

.wrapper-button {
    margin-top: 24px;

    a {
        display: grid;
        background: #4e4e4e;
        border: 1px solid #4e4e4e;
        color: #fff;
        border-radius: 20px;
        width: 150px;
        text-align: center;
        padding: 6px 6px 8px;
        font-family: $font-opensans-bold;
        text-decoration: none;
        margin: 0 auto;
    }
}


.wrapper-content-footer-two {
    background: #fff;
    padding: 40px;

    .title {
        text-transform: uppercase;
        font-size: 2rem;
        margin-bottom: 24px;
    }
}


.list-othersites {
    grid-gap: 16px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;

    li {
        background: #f4f4f4;
        display: flex;
        align-items: center;

        a {
            display: grid;
            width: 100%;
            height: 100%;
            align-items: center;
            padding: 16px;
        }

        img {
            width: 100%;
            height: auto;
            max-width: 155px;
            margin: 0 auto;
            display: block;
        }

        &:hover {
            background: #eeeeee;
        }
    }


}



@media screen and (max-width: 768px) {
    .wrapper-content-footer-two {
        padding: 30px 16px;
    }
}

@media screen and (max-width: 680px) {
    .list-othersites {
        grid-gap: 16px;
        display: grid;
        grid-template-columns: auto auto auto;

        li {
            height: 130px;
        }
    }

}

@media screen and (max-width: 550px) {
    .footer-content .bloc-two {
        grid-template-columns: auto;
        grid-gap: 32px;
    }

    .logo-af img {
        max-width: 230px;
    }

    .bloc-footer .bloc-two {
        grid-template-columns: 30px auto;
        row-gap: 40px;
    }

}

@media screen and (max-width: 500px) {
    .list-othersites {
        grid-template-columns: auto auto;
    }

    .list-othersites {
        li {
            height: 130px;
        }
    }
}


@media screen and (max-width: 330px) {
    .list-othersites {
        grid-template-columns: auto;
    }

    .list-othersites {
        li {
            height: 190px;
        }
    }
}