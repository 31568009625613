@font-face {
    font-family: 'font-opensans-bold';
    src: url(../assets/fonts/OpenSansCondensed-Bold.ttf);
}

@font-face {
    font-family: 'font-opensans-light';
    src: url(../assets/fonts/OpenSansCondensed-Light.ttf);
}

@font-face {
    font-family: 'font-opensans-italic';
    src: url(../assets/fonts/OpenSansCondensed-LightItalic.ttf);
}

$font-opensans-bold: "font-opensans-bold", sans-serif;
$font-opensans-light: "font-opensans-light", sans-serif;
$font-opensans-italic: "font-opensans-italic", sans-serif;


@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

body {
    font-family: $font-opensans-light;
    color: #546E7A;
    font-size: 16px;
    font-weight: 300;
    background: #fff;
    overflow-x: hidden;
    margin: 0;
}

html {
    overflow-x: hidden;
}

header {
    position: absolute;
    z-index: 999;
    width: 100%;
}

ul {
    margin: 0;
    padding: 0;
}

ul {
    li {
        list-style: none;
    }
}

strong {
    font-family: $font-opensans-bold;
}

.has-error {
    border: 1px solid red;
}

.has-text-align-center {
    text-align: center;
}

.has-large-font-size {
    font-size: 1.5rem;
}

.has-extra-large-font-size {
    font-size: 2.5rem;
}

.w-100 {
    width: 100% !important;
}

.justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.wrapper-content-body .pno-padding {

    padding-top: 50px;

    P {
        padding: 0 16px;
    }

    .wp-block-column {
        padding-top: 16pw;
    }

    .wp-block-image {
        background-color: transparent;
    }
}

.wp-block-column.social-icons figure.wp-block-image {
    margin-top: 0;
    padding-top: 0;
    background: transparent;
}